/*
 * @Auth: linjituan
 * @Date: 2023-01-16 20:03:09
 * @LastEditors: linjituan
 * @LastEditTime: 2023-01-16 20:44:50
 */

export function getArticleTime(item) {
  console.log('getArticleTime', item)
  if (item.articleTime) {
    const date = item.articleTime.split(' ')[0]
    const year = date.split('-')[0]
    const month = date.split('-')[1]
    const day = date.split('-')[2]
    return year + '年' + month + '月' + day + '日'
  }
  return item.createTime
}
